import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {
  "title": "Lightning Talks Sponsor として RubyKaigi 2018 に参加してきました",
  "date": "2018-06-06T06:13:05.000Z",
  "slug": "entry/2018/06/06/151300",
  "tags": ["medley"],
  "hero": "./2018_06_06.png",
  "heroAlt": "rubykaigi 2018"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p>{`こんにちは！開発本部のエンジニア・後藤です。`}</p>
    <p>{`メドレーは 5/31〜6/2 に開催された`}<a parentName="p" {...{
        "href": "https://rubykaigi.org/2018"
      }}>{`RurbyKaigi 2018`}</a>{`に Lightning Talks Sponsor として協賛させていただきました（`}<a parentName="p" {...{
        "href": "https://developer.medley.jp/entry/2017/09/28/120000"
      }}>{`昨年`}</a>{`の Ruby Sponsor に続き、2 年目の協賛です）。`}</p>
    <p>{`イベント当日は、弊社から CTO の平山、採用・広報の阿部と深澤、エンジニアの田中、宍戸、後藤の 6 人が参加しました。今回はその様子などをレポートします。`}</p>
    <h1>{`会場の様子`}</h1>
    <p>{`RubyKaigi 2018 は`}<a parentName="p" {...{
        "href": "https://rubykaigi.org/2018/venue"
      }}>{`仙台国際センター`}</a>{`での開催でした。昨年は`}<a parentName="p" {...{
        "href": "https://rubykaigi.org/2017/venue"
      }}>{`広島`}</a>{`、一昨年は`}<a parentName="p" {...{
        "href": "https://rubykaigi.org/2016/venue"
      }}>{`京都`}</a>{`ということで、これで天橋立・宮島・松島の日本三景をめぐる旅が一旦完結になりますね。`}</p>
    <img {...{
      "src": "https://cdn-ak.f.st-hatena.com/images/fotolife/m/medley_inc/20180605/20180605172640.jpg",
      "alt": "20180605172640.jpg"
    }}></img>
    <p>{`仙台国際センターは仙台駅から地下鉄東西線で 3 駅目というアクセスの良い好立地にありながら緑に囲まれた心地よい場所にありました。`}</p>
    <p>{`メイン会場は 1000 人収容できる広い会場になっています。各セッション、この会場が埋まるぐらいの盛況ぶりでした。`}</p>
    <img {...{
      "src": "https://cdn-ak.f.st-hatena.com/images/fotolife/m/medley_inc/20180605/20180605172745.jpg",
      "alt": "20180605172745.jpg"
    }}></img>
    <p>{`世界地図&日本地図。様々な地域からの参加者がいますね！（rubyists に map メソッドをかましてますね。ブロック内容は各参加者がシールを貼って実装。）`}</p>
    <img {...{
      "src": "https://cdn-ak.f.st-hatena.com/images/fotolife/m/medley_inc/20180605/20180605172857.jpg",
      "alt": "20180605172857.jpg"
    }}></img>
    <p>{`地図の隣のスポンサーボード覧にメドレーロゴがあることを確認してパシャり。`}</p>
    <img {...{
      "src": "https://cdn-ak.f.st-hatena.com/images/fotolife/m/medley_inc/20180605/20180605173736.png",
      "alt": "20180605173736.png"
    }}></img>
    <h1>{`ブースの様子`}</h1>
    <p>{`続いてブースの様子を紹介します。メドレーコーポレートカラーの赤をベースとした以下な感じの仕上がりになりました。`}</p>
    <img {...{
      "src": "https://cdn-ak.f.st-hatena.com/images/fotolife/m/medley_inc/20180605/20180605173908.jpg",
      "alt": "20180605173908.jpg"
    }}></img>
    <blockquote className="twitter-tweet"><p lang="en" dir="ltr">All photographs will be uploaded to this URLs(google photo).<br />day1 <a href="https://t.co/w6Dgq8HBb4">https://t.co/w6Dgq8HBb4</a><br />day2 <a href="https://t.co/X1x03bFEDF">https://t.co/X1x03bFEDF</a><br />day3 <a href="https://t.co/8rlXpfZTEB">https://t.co/8rlXpfZTEB</a><br /><br />See you next year! <a href="https://twitter.com/hashtag/RubyKaigi?src=hash&amp;ref_src=twsrc%5Etfw">#RubyKaigi</a> <a href="https://twitter.com/hashtag/RubyKaigi2018?src=hash&amp;ref_src=twsrc%5Etfw">#RubyKaigi2018</a></p>&mdash; nil (@KatsumaNarisawa) <a href="https://twitter.com/KatsumaNarisawa/status/1002858233453633541?ref_src=twsrc%5Etfw">June 2, 2018</a></blockquote> <script async src="https://platform.twitter.com/widgets.js" charSet="utf-8"></script>
    <p>{`ノベルティも用意してブースにお越しいただいた方にお配りしていました。ステッカー、うちわ、パンフレットに加えて医療らしさが伝わる絆創膏も用意しました。`}</p>
    <img {...{
      "src": "https://cdn-ak.f.st-hatena.com/images/fotolife/m/medley_inc/20180605/20180605174458.jpg",
      "alt": "20180605174458.jpg"
    }}></img>
    <p>{`ブースにはおかげさまで、たくさんの方にお越しいただきました！`}</p>
    <img {...{
      "src": "https://cdn-ak.f.st-hatena.com/images/fotolife/m/medley_inc/20180605/20180605174539.jpg",
      "alt": "20180605174539.jpg"
    }}></img>
    <h1>{`CTO 平山の発表`}</h1>
    <p>{`そして、初日の Lightning Talks 前のスポンサーの PR 枠にて弊社の CTO 平山が発表をしました。`}</p>
    <img {...{
      "src": "https://cdn-ak.f.st-hatena.com/images/fotolife/m/medley_inc/20180606/20180606145341.png",
      "alt": "20180606145341.png"
    }}></img>
    <img {...{
      "src": "https://cdn-ak.f.st-hatena.com/images/fotolife/m/medley_inc/20180605/20180605175431.jpg",
      "alt": "20180605175431.jpg"
    }}></img>
    <p>{`発表では、「医療ヘルスケア分野の課題を解決する」というミッションのもとメドレーが 4 つの事業を行っていること、また、以前`}<a parentName="p" {...{
        "href": "https://developer.medley.jp/entry/2018/05/01"
      }}>{`本ブログで紹介`}</a>{`しました 3 本のニュースリリースを含めたこの 1 年のアップデート内容を中心に紹介させていただきました。`}</p>
    <img {...{
      "src": "https://cdn-ak.f.st-hatena.com/images/fotolife/m/medley_inc/20180605/20180605175456.jpg",
      "alt": "20180605175456.jpg"
    }}></img>
    <p>{`公演の途中での CTO 平山からメドレーのことを知っている人？との問いかけで、7・ 8 割の方が挙手をしていたのは感慨深かったです。`}</p>
    <p>{`当日のスライドはこちらです。`}</p>
    <iframe className="speakerdeck-iframe" frameBorder="0" src="https://speakerdeck.com/player/f09aaf0eccc14d9f9e958c5957c30823" title="Company &amp; Product Information  /RubyKaigi2018" allowFullScreen="true" mozallowfullscreen="true" webkitallowfullscreen="true" style={{
      "border": "0px",
      "background": "padding-box padding-box rgba(0, 0, 0, 0.1)",
      "margin": "0px",
      "padding": "0px",
      "borderRadius": "6px",
      "boxShadow": "rgba(0, 0, 0, 0.2) 0px 5px 40px",
      "width": "560px",
      "height": "420px"
    }} data-ratio="1.3333333333333333"></iframe>
    <h1>{`現地での反応`}</h1>
    <p>{`ブース展示、PR 枠での発表を通じて以下のような嬉しい反応もいただきました！`}</p>
    <blockquote className="twitter-tweet"><p lang="ja" dir="ltr">オンライン診療はもっと普及すべきですね、地方医療の課題解決にも繋がる素晴らしい取り組みだと思う。<a href="https://twitter.com/hashtag/rubikaigi2018?src=hash&amp;ref_src=twsrc%5Etfw">#rubikaigi2018</a> <a href="https://twitter.com/hashtag/rubykaigi?src=hash&amp;ref_src=twsrc%5Etfw">#rubykaigi</a><a href="https://twitter.com/hashtag/%E3%83%A1%E3%83%89%E3%83%AC%E3%83%BC?src=hash&amp;ref_src=twsrc%5Etfw">#メドレー</a></p>&mdash; ふじこ / SHOWROOM の人事おねえさん (@yufujikochan) <a href="https://twitter.com/yufujikochan/status/1002106292163366912?ref_src=twsrc%5Etfw">May 31, 2018</a></blockquote> <script async src="https://platform.twitter.com/widgets.js" charSet="utf-8"></script>[https://twitter.com/yucao24hours/status/1002105819297595392:embed]
    <blockquote className="twitter-tweet"><p lang="ja" dir="ltr">指先を切ってしまったけど絆創膏をノベルティで配ってるから安心 <a href="https://twitter.com/hashtag/rubykaigi?src=hash&amp;ref_src=twsrc%5Etfw">#rubykaigi</a> <a href="https://t.co/NeXWfTif6m">pic.twitter.com/NeXWfTif6m</a></p>&mdash; Motonori Iwata (@mobcov) <a href="https://twitter.com/mobcov/status/1002397188226351104?ref_src=twsrc%5Etfw">June 1, 2018</a></blockquote> <script async src="https://platform.twitter.com/widgets.js" charSet="utf-8"></script>
    <p>{`ブースでも、2 日目以降「1 日目のセッション見ましたよー」と話してくださる方も多く、メドレーとそのプロダクトについて Rubyist の皆様に知っていただくとても良い機会になっていたと思いました。`}</p>
    <h1>{`セッションの様子`}</h1>
    <p>{`エンジニアはブースでの会社紹介の合間に各自気になったセッションを聴講したりもしました。
感覚ですが mruby や型、パフォーマンス周りの話題が多かったように思います。まさに 2018 年現在の Ruby を取り巻く環境を表している感じがします。エンジニアとしてこういった技術のセッションを聞けるのは純粋に楽しいですし、日々の開発に活かせそうなネタもあったりと、とても有意義な時間を過ごせました。`}</p>
    <img {...{
      "src": "https://cdn-ak.f.st-hatena.com/images/fotolife/m/medley_inc/20180605/20180605175826.jpg",
      "alt": "20180605175826.jpg"
    }}></img>
    <p><a parentName="p" {...{
        "href": "https://rubykaigi.org/2018/presentations/yukihiro_matz.html#may31"
      }}>{`初日の keynote`}</a>{`での 1 コマ。`}</p>
    <p>{`Matz さんの keynote にもありましたが何事も「塞翁が馬」です。毎年 Ruby は死に、そして生まれ変わります（クリスマスに）。Ruby も常に進化していることを肌で感じることのできるセッション群でした。`}</p>
    <h1>{`番外編`}</h1>
    <p>{`さて、メドレー恒例（？）のお参りですが今回は大崎八幡宮に参詣することになりました。`}</p>
    <p>{`大崎八幡宮の社殿は国宝にも指定されており、安土桃山時代の豪華絢爛な様式の建築でとても雰囲気のある神社でした。`}</p>
    <img {...{
      "src": "https://cdn-ak.f.st-hatena.com/images/fotolife/m/medley_inc/20180605/20180605175920.jpg",
      "alt": "20180605175920.jpg"
    }}></img>
    <p>{`参拝する 3 人。`}</p>
    <img {...{
      "src": "https://cdn-ak.f.st-hatena.com/images/fotolife/m/medley_inc/20180605/20180605175948.jpg",
      "alt": "20180605175948.jpg"
    }}></img>
    <p>{`参拝する 2 人とそれを撮影する 2 人。`}</p>
    <h1>{`さいごに`}</h1>
    <p>{`昨年に引き続きメドレーの RubyKaigi 協賛は 2 度目になりました。`}</p>
    <p>{`ブースで会社やプロダクトの説明していると「メドレー知ってます」との声を聞く機会も多く、とても嬉しい限りでした。これまで以上に Ruby、医療 ×IT を盛り上げていければという気持ちを胸に仙台を後にしました。`}</p>
    <img {...{
      "src": "https://cdn-ak.f.st-hatena.com/images/fotolife/m/medley_inc/20180605/20180605180049.jpg",
      "alt": "20180605180049.jpg"
    }}></img>
    <p>{`（新幹線から仙台の夕焼けをパシャリ）`}</p>
    <h1>{`お知らせ`}</h1>
    <p>{`弊社では「医療 x IT への挑戦」に取り組みたいエンジニアのみなさんを心からお待ちしております！
興味がある方は、こちらの「話を聞いてみたい」からご連絡ください。`}</p>
    <p><a parentName="p" {...{
        "href": "https://www.wantedly.com/companies/medley"
      }}>{`https://www.wantedly.com/companies/medley`}</a></p>
    <p>{`開発本部の雰囲気をもっと知りたい方は、こちらからどうぞ。
`}<a parentName="p" {...{
        "href": "https://www.medley.jp/recruit/creative.html"
      }}>{`https://www.medley.jp/recruit/creative.html`}</a></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      